<template>
  <BaseLayout>
    <template #header>
      <h2>Pemeriksaan Donor</h2>
      <p class="hp-p1-body mb-0 text-black">
        Semua (10)
      </p>
      <div class="d-flex justify-content-between">
        <b-button
          variant="primary-2"
          to="/dashboards/form-examination"
        >
          Tambah Data Pemeriksaan
        </b-button>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
      <div class="row no-gutters mt-3">
        <b-form-group
          label="Lokasi MU"
          label-for="location"
          class="mr-3"
          style="min-width: 300px;"
        >
          <registered-m-u-lists
            v-model="selectedMU"
            @input="handleInputMU"
          />
        </b-form-group>
        <b-form-group
          label="Pilih Tanggal"
          label-for="bydate"
        >
          <b-form-input
            v-model="selectedDate"
            type="date"
            @input="handleDateChange"
          />
        </b-form-group>
      </div>
    </template>
    <template #content>
      <b-table
        style="overflow: auto; white-space: nowrap"
        :busy.sync="table.loading"
        :fields="fields"
        :items="table.items"
        responsive="sm"
        show-empty
      >
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template #empty>
          <div class="hp-p1-body text-center my-2">
            <strong>Tidak Ada Data</strong>
          </div>
        </template>
        <template #cell(status)="row">
          <b-badge :variant="row.item.status === 'Done' ? 'success' : row.item.status === 'Not Finish' ? 'primary': row.item.status === 'Waiting' ? 'warning' : 'danger'">
            {{ row.item.status === 'Done' ? 'Selesai' : row.item.status === 'Not Finish' ? 'Belum Selesai' : row.item.status === 'Waiting' ? 'Menunggu' : 'Ditolak' }}
          </b-badge>
        </template>

        <template #cell(action)="row">
          <div
            v-if="row.item.status === 'Done' || row.item.status === 'Rejected'"
            style="display: flex; gap: 8px;"
          >
            <b-button variant="info">
              Edit
            </b-button>
            <b-button variant="primary">
              Detail
            </b-button>
            <b-button variant="warning">
              Laporan
            </b-button>
          </div>

          <b-button
            v-if="row.item.status === 'Not Finish'"
            variant="primary"
          >
            Detail
          </b-button>

          <b-button
            v-if="row.item.status === 'Waiting'"
            variant="success"
          >
            Pemeriksaan
          </b-button>
        </template>

      </b-table>
      <b-pagination
        v-model="table.currentPage"
        :total-rows="table.total"
        :per-page="table.perPage"
        class="mb-3"
        align="right"
        @change="fetchData"
      />
    </template>
  </BaseLayout>
</template>
<script>
import {
  BButton, BFormInput, BInputGroup, BFormGroup, BInputGroupPrepend, BTable, BBadge, BPagination, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import manageExams from '../../../../api/examinations/manageExams'
import tableDataMixins from '../../../../mixins/tableDataMixins'
import RegisteredMULists from '../../../../components/shared/RegisteredMULists.vue'

export default {
  name: 'ListDonorParticipant',
  components: {
    BSpinner,
    BInputGroupPrepend,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BButton,
    BTable,
    BBadge,
    BPagination,
    RegisteredMULists,
    vSelect,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      fields: [
        {
          key: 'registration_id',
          label: 'No. Registrasi',
        },
        {
          key: 'user_name',
          label: 'Nama',
        },
        {
          key: 'location',
          label: 'Lokasi',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
      groupedByStatus: [
        { text: 'Berhasil', value: 'berhasil' },
        { text: 'Gagal', value: 'gagal' },
        { text: 'Process', value: 'proses' },
      ],
      selectedMU: null,
      selectedDate: '',
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(additionalParams = {}) {
      this.table.loading = true

      const params = {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.search,
        date: this.selectedDate,
        ...additionalParams,
      }

      await manageExams.getAll(params).then(response => {
        this.table.items = response.data.data.data
        this.table.total = response.data.data.total
      })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    getLocationParams(selectedValue) {
      if (!selectedValue) return null

      const additionalParams = {
        hospital_id: selectedValue.type === 'hospital' ? selectedValue.hospital_id : null,
        blood_van_id: selectedValue.type === 'blood_van' ? selectedValue.id : null,
      }

      Object.keys(additionalParams).forEach(key => additionalParams[key] === null && delete additionalParams[key])
      return additionalParams
    },
    handleInputMU(selectedValue) {
      this.selectedMU = selectedValue
      const params = this.getLocationParams(selectedValue)

      if (params && this.selectedDate) {
        this.fetchData(params)
      } else {
        this.fetchData()
      }
    },

    handleDateChange(date) {
      this.selectedDate = date
      const params = this.getLocationParams(this.selectedMU)
      if (params && date) {
        this.fetchData(params)
      } else {
        this.fetchData()
      }
    },

    changePage(page) {
      this.table.currentPage = page
      this.fetchData()
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer)

      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable()
      }, 500)
    },
    onChangeSearchTable() {
      this.fetchData()
    },
  },
}
</script>
<style scoped>

</style>
